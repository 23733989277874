import React from "react";
import { useEffect } from "react";
import { useState } from "react";

import ImageGallery from "react-image-gallery";
// import stylesheet if you're not already using CSS @import
import "react-image-gallery/styles/css/image-gallery.css";

export const GalleryTemplate = () => {

  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  const isMobile = width <= 768;

  const items = [
    {
      original: window.location.origin + '/img/gallery/1.jpg',
      originalHeight: isMobile ? "250" : "750",
      description: "Santa",
    },
    {
      original: window.location.origin + '/img/gallery/2.jpg',
      originalHeight: isMobile ? "250" : "750",
      description: "Renna",
    },
    {
      original: window.location.origin + '/img/gallery/3.jpg',
      originalHeight: isMobile ? "250" : "750",
      description: "Pinguino",
    },
    {
      original: window.location.origin + '/img/gallery/4.jpg',
      originalHeight: isMobile ? "250" : "750",
      description: "Orsetta",
    },
    {
      original: window.location.origin + '/img/gallery/5.jpg',
      originalHeight: isMobile ? "250" : "750",
      description: "Leone",
    },
    {
      original: window.location.origin + '/img/gallery/6.jpg',
      originalHeight: isMobile ? "250" : "750",
      description: "Orsetto",
    },
    {
      original: window.location.origin + '/img/gallery/7.jpg',
      originalHeight: isMobile ? "250" : "750",
      description: "Angioletto",
    },
    {
      original: window.location.origin + '/img/gallery/8.jpg',
      originalHeight: isMobile ? "250" : "750",
      description: "Winnie the Pooh",
    },
  ];

  return (
    <div id="portfolio" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Gallery</h2>
          I miei pasticci creativi, prodotti rigorosamente fatti a mano da personalizzare e regalare. Cucito creativo, uncinetto, disegno, ricamo, sono tante le tecniche che mi permettono di creare oggetti unici e originali che spaziano dai regali nascita alle bomboniere, dalle decorazioni natalizie ad accessori moda.
          <br></br>
          La galleria mostra solo alcuni dei prodotti che ho realizzato.
          <br></br>
          Contattami per saperne di più!
        </div>
        <div className="row">
          <div className="portfolio-items">
            <div className="col-xs-12">
              <ImageGallery
                showPlayButton={false}
                items={items}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
