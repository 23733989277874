import React from "react";

export const About = (props) => {
  return (
    <div id="about">
      <div className="container">
        <div className="row">
          <div className="col-xs-5 col-md-2 justify-content-center">
            <img src="img/about.png" style={{
              boxShadow: "none"
            }}
              className="img-responsive" alt="" />{" "}
          </div>
          <div className="col-xs-7 col-md-10">
            <div className="about-text">
              <h2>CHI SONO?</h2>
              <p>Eccomi quì! <br></br>
                Io sono Gianna! Poco architetto, molto artigiano. Ciò che mi spinge è il divertimento che provo a dare forme e ordine a qualsiasi idea mi passi per la testa.<br></br> Tutto ciò è cominciato grazie all'aiuto dei miei amici, miei fedelissimi fan, e all'improvvisa necessità di condividere creatività, voglia di evadere e fantasia.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
